<template>
  <div class="intro-page">
    <div class="intro-page__body">
      <c-icon-star class="intro-page__icon" color="green"></c-icon-star>
      <div>Вас ждут 11 сюжетных линий и разговоры о первой любви, буллинге и других сложных темах. Вам помогут советы психологов и видеолекция о том, как обсуждать с подростком алкоголь.</div>
      <br>
      <router-link class="c-btn" :to="{ name: 'choice-branch' }">
        Выбрать тему
      </router-link>
    </div>
    <p class="intro-page__copy">
      CONVERTER — проект компании HEINEKEN. У&nbsp;нас тоже есть дети. И&nbsp;так же&nbsp;как и&nbsp;вы, мы с&nbsp;ними ищем возможность услышать друг друга и&nbsp;решать возникающие проблемы совместно.
    </p>
  </div>
</template>

<script>
import CIconStar from "@/components/design/c-icon-star";

export default {
  name: "IntroPage",
  components: { CIconStar },
  mounted() {
    this.$store.commit("setBackground", "home");
    this.$store.commit("setShowPhoneOpacity", true);
  },
};
</script>

<style lang="sass">
@import "src/assets/css/media"
.intro-page
  color: #fff
  text-align: center
  padding: 0 20px
  display: flex
  flex-direction: column
  align-items: center
  justify-content: space-around
  height: 100%
  &__icon
    margin-top: 0px
    transform: translateY(-50%)
    @include media-md
      margin-top: -40px
    @include media-h-max-custom(630px)
      margin-top: 0
  &__body
    width: 100%
    margin-top: 130px
    background: #fff
    color: #333
    border-radius: 10px
    padding: 0 15px 20px 15px
    font-weight: bold
    font-size: 1.1rem
    line-height: 130%
    @include media-md
      padding: 40px 15px
    @include media-h-max-custom(630px)
      padding: 0 15px 20px 15px
      margin-top: 80px
    .c-btn
      width: 230px
      max-width: 90%
  &__copy
    margin-top: 2rem
    font-style: italic
    font-size: 1rem
    padding-bottom: 2rem
</style>
