<template>
  <div class="home-content centered">
    <c-icon-star class="home-content__icon" color="green"></c-icon-star>
    <h1 class="home-content__title">CONVERTER</h1>
    <p>
      Игра о том, как найти общий язык<br> с&nbsp;подростком и конвертировать<br> конфликты&nbsp;в&nbsp;диалог.
    </p>
    <router-link v-if="$route.name === 'home'" class="c-btn home-content__btn" :to="{ name: 'intro' }"
      >Начать игру</router-link
    >
  </div>
</template>

<script>
import CIconStar from "@/components/design/c-icon-star";
export default {
  name: "HomePage",
  components: { CIconStar },
  mounted() {
    this.$store.commit("setBackground", "home");
    this.$store.commit("setShowPhoneOpacity", true);
  },
};
</script>

<style lang="sass">

.home-content
  color: #fff
  text-align: center
  height: 88vh
  max-height: 824px
  min-height: 500px
  width: 100%
  z-index: 100
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  @media (max-width: 425px)
    height: 100%
    margin-top: -50px
  &__icon
    margin-bottom: 3rem
    @media (max-height: 700px)
      margin-bottom: 7rem
    @media (max-height: 620px)
      margin-bottom: 6rem
    @media (max-height: 531px) and (max-width: 425px)
      margin-bottom: 4rem
  &__btn
    margin-top: 30px
  &__title
    //position: absolute
    //left: 50%
    //transform: translateX(-50%)
    //top: 18rem
    position: relative
    font-size: 11rem
    line-height: 100%
    margin: 0 0 2rem
    opacity: 1
    transition: opacity 500ms ease-in-out, top 400ms ease-in-out
    @media (max-height: 531px)
      margin-bottom: 2rem
    @media (max-width: 425px)
      &:before
        content: ''
        position: absolute
        background-image: url("/img/home_bg--collage.png")
        background-size: cover
        width: 7rem
        height: 4.6rem
        top: -13vw
        z-index: 1
    @media (max-width: 1000px)
      font-size: 14vw
  p
    font-size: 1.25rem
    line-height: 1.6rem
    margin: 0 auto
    opacity: 1
    transition: opacity 500ms ease-in-out, top 400ms ease-in-out
</style>
