import Vue from "vue";
import Router from "vue-router";
import MailPage from "@/pages/Mail";
import AdvicePage from "@/pages/Advice";
import AboutPage from "@/pages/About";
import EndPage from "@/pages/End";
import HomePageWrapper from "@/pages/Main/HomePageWrapper";
import HomePage from "@/pages/Main/HomePage";
import IntroPage from "@/pages/Main/IntroPage";
import ChoiceBranchPage from "@/pages/Main/ChoiceBranchPage";
import SetupPage from "@/pages/Main/SetupPage";
import ChatPage from "@/pages/Main/ChatPage";
import MemoriesPage from "@/pages/MemoriesPage";
import ArticlesPage from "@/pages/ArticlesPage";

Vue.use(Router);

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: "/",
            component: HomePageWrapper,
            children: [
                {
                    path: "/",
                    name: "home",
                    component: HomePage,
                    meta: {
                        slot: "outer",
                        title: "CONVERTER. Конвертирует конфликты в диалог"
                    },
                },
                {
                    path: "intro",
                    name: "intro",
                    component: IntroPage,
                    meta: {
                        title: "CONVERTER. Проект компании HEINEKEN"
                    }
                },
                {
                    path: "choice",
                    name: "choice-branch",
                    component: ChoiceBranchPage,
                    meta: {
                        title: "CONVERTER. Выбор темы"
                    }
                },
                {
                    path: "setup/:type",
                    name: "setup",
                    props: true,
                    component: SetupPage,
                    meta: {
                        title: "CONVERTER. Выбор пола и имени"
                    }
                },
                {
                    path: "chat/:gender?/:branch-:end?/:step?/:finaleStage?",
                    name: "chat",
                    props: route => {
                        const branch = parseInt(route.params.branch);
                        const step = parseInt(route.params.step);
                        const end = parseInt(route.params.end);
                        const gender = route.params.gender;
                        const finaleStage = route.params.finaleStage;
                        return {
                            branch,
                            step,
                            end,
                            gender,
                            finaleStage,
                        };
                    },
                    component: ChatPage,
                },
            ],
        },
        {
            path: "/memories",
            name: "memories",
            component: MemoriesPage,
            meta: {
                title: "CONVERTER. Выбор воспоминания"
            }
        },
        {
            path: "/memories/:id/:backStep?",
            name: "memory",
            props: true,
            component: () => import("@/pages/MemoryPage"),
        },
        {
            path: "/articles",
            name: "articles",
            component: ArticlesPage,
            meta: {
                title: "CONVERTER. Выбор материалов"
            }
        },
        {
            path: "/article/:id",
            name: "article",
            props: true,
            beforeEnter: function (to, from, next) {
                next()
            },
            component: () => import("@/pages/ArticleDetailPage"),
        },
        {
            path: "/mail",
            name: "Mail",
            component: MailPage,
        },
        {
            path: "/advice",
            name: "Advice",
            component: AdvicePage,
            meta: {
                title: "CONVERTER. Несоветы от родителей подростков"
            }
        },
        {
            path: "/about",
            name: "About",
            component: AboutPage,
            meta: {
                title: "CONVERTER. О проекте"
            }
        },
        {
            path: "/end",
            name: "End",
            component: EndPage,
        },
        {
            path: "/test",
            name: "test",
            component: () => import("@/pages/TestPage"),
        },
        {
            path: "/privacy-policy",
            name: "privacy-policy",
            component: () => import("@/pages/PolicyPage"),
        },
        {
            path: "/editor",
            name: "editor",
            component: () => import("@/pages/Editor/EditorPage"),
            children: [
                {
                    path: "chat",
                    name: "editor-chat",
                    component: () => import("@/pages/Editor/EditorChatPage"),
                },
                {
                    path: "collage",
                    name: "editor-collage",
                    component: () => import("@/pages/Editor/EditorCollagePage"),
                },
                {
                    path: "article",
                    name: "editor-article",
                    component: () => import("@/pages/Editor/EditorArticlePage"),
                },
            ],
        },
    ],
});

router.beforeEach((to, from, next) => {
    let title = document.title

    if (to.name === 'chat') {
        if (to.params.finaleStage) {
            switch (to.params.finaleStage) {
                case 'congratulation':
                    title = 'congratulation'
                    break;
                case 'action':
                    title = 'action'
                    break;
                case 'share':
                    title = 'share'
                    break;
            }
        } else {
            title = chatMeta.filter(i => i.path === to.params.gender + '-' + to.params.branch)[0].title || title
        }
    } else if (typeof to.meta.title !== "undefined") {
        title = to.meta.title
    }

    document.title = title
    next()
})

export default router